import Toastify from 'toastify-js';

const colors = {
  info: '#4259ed',
  success: '#1ae5be',
  warning: '#fca93f',
  error: '#fe6464',
};

const notify = (text, type = 'info', position = 'center', gravity = 'bottom') => {
  const options = {
    text,
    gravity,
    position,
    duration: 5000,
    backgroundColor: colors[type],
  };
  Toastify(options).showToast();
};

window.notify = notify;

export default notify;
