const SimpleLightbox = require('simple-lightbox');

$(function () {
  const lightbox = $('.lightbox');
  lightbox.each(function () {
    const elements = $(this).find('a');
    if (elements.length) {
      new SimpleLightbox({ elements });
    }
  });
});
