$(function () {
  const buttons = {
    once: $('button[data-button-once]'),
  };

  if (buttons.once.length) {
    const { once } = buttons;
    once.each(function () {
      const button = $(this);
      button.closest('form').on('submit', function () {
        button.addClass('is-loading');
      });
    });
  }
});
