const $ = require('jquery');
window.$ = window.jQuery = $;
import inputmask from 'inputmask';

require('./admin/components');
require('./admin/elements');

(function ($, undefined) {
  const phones = document.querySelectorAll('input[type="tel"]');
  if (phones) {
    phones.forEach((field) => {
      Inputmask({ mask: '+46999999999' }).mask(field);
    });
  }
})(jQuery);
